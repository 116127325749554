// "2025-Q1": {
  //   veloPartNumber: "VCH-5000-D1N",
  //   veloDescription: "VDURA Certified Hardware 5000 – 1U Director Node,  Dual 25/10GbE ",
  //   veloCost: 10446,
  //   vpodPartNumber: "VCH-5000-S1N",
  //   vpodDescription: "VDURA Certified Hardware 5000 – 1U Storage Node ",
  //   vpodCost: 10446,
  //   jbod78PartNumber: "VCH-5000-J78-2592s",
  //   jbod78Description: "VDURA Certified Hardware 5000 – 4U SAS4 JBOD, 78 HDDs, 1m Racks",
  //   jbod78Cost: 10446,
  //   jbod108PartNumnber: "VCH-5000-J108-2592s",
  //   jbod108Description: "VDURA Certified Hardware 5000 – 4U SAS4 JBOD, 108 HDDs, 1.2m Racks",
  //   jbod108cost: 8061,
  //   ssd_3_84PartNumber: "VCH-NVME-3.8s",
  //   ssd_3_84Description: "VDURA Certified Hardware – 3.8TB SSD, PCIe 5.0, SED",
  //   ssd_3_84Cost: 881,
  //   ssd_7_68PartNubmer: "VCH-NVME-7.68",
  //   ssd_7_68Description: "VDURA Certified Hardware – 7.68TB SSD, PCIe 5.0, SED",
  //   ssd_7_68Cost: 1300,
  //   ssd_15_36PartNumber: "VCH-NVME-15.36",
  //   ssd_15_36Description: "VDURA Certified Hardware – 15.36TB SSD, PCIe 5.0, SED",
  //   ssd_15_36Cost: 2628,
  //   ssd_30_72PartNumber: "VCH-NVME-30.72",
  //   ssd_30_72Description: "VDURA Certified Hardware – 30.72TB SSD, PCIe 5.0, SED",
  //   ssd_30_72Cost: 5200,
  //   hdd_18PartNumber: 350,
  //   hdd_18Description: 350,
  //   hdd_18Cost: 350,
  //   hdd_24PartNumber: 350,
  //   hdd_24Description: 350,
  //   hdd_24Cost: 350,
  //   hdd_30PartNumber: 550,
  //   hdd_30Description: 550,
  //   hdd_30Cost: 550,
  //   hdd_32: 600,
  //   ssdSoftwarePartNumber: "VDP-SW-P-10-HP",
  //   ssdSoftwareDescription: "VDURA Data Platform – Physical, 10TB, High Performance Tier, One Month Subscription Term",
  //   ssdSoftwareCost: 100,  
  //   hddSoftwarePartNumber: "VDP-SW-P-10-C",  
  //   hddSoftwareDescription: "VDURA Data Platform – Physicial, 10TB, Capacity Tier, One Month Subscription Term", 
  //   hddSoftwareCost: 8, 
  //   softwareDiscountPartNumber: "VDP-SW-P-10-PD",
  //   softwareDiscountDescription: "VDURA Data Platform – Physical, 10TB, One Month Subscription Term Promotion Discount", 
  //   softwareDiscountCost: -12,  
  //   installPartNumber: "SVC-R1-CINT-PDEP-NORACK",  
  //   installDescription: "First Rack, Customer Integration, Panasas Deployment, No Rack", 
  //   installCost: 7590    
  // },

export const quarterlyPricing = {// Mock data - In real app, this would come from your database
  
   "2025-Q1": {
    velo: 10446,
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 881,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, 
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 7.87
  },
  "2025-Q2": {
    velo: 10446,
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 881,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, // Default to 0 months
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 75
  },
  "2025-Q3": {
    velo: 10446,
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 881,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, // Default to 0 months
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 75
  },
  "2025-Q4": {
    velo: 10446,
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 881,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, // Default to 0 months
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 75
  },
  "2026-Q1": {
    velo: 10446,
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 881,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, // Default to 0 months
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 75
  },
  "2026-Q2": {
    velo: 10446,
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 881,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, // Default to 0 months
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 75
  },
  "2026-Q3": {
    velo: 10446,
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 763,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, // Default to 0 months
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 7.87
  },
  "2026-Q4": {
    
    vpod: 15996,
    jbod78: 7028,
    jbod108: 8061,
    ssd_1_92: 406,
    ssd_3_84: 881,
    ssd_7_68: 1300,
    ssd_15_36: 2628,
    ssd_30_72: 5200,
    hdd_16: 300,
    hdd_18: 350,
    hdd_24: 350,
    hdd_30: 550,
    hdd_32: 600, // Default to 0 months
    ssdSoftware: 100, 
    hddSoftware: 8, 
    softwareDiscount: 75
  }
  // Add more quarters here
};

export const veloSsdCapacities = [1.92, 3.84, 7.68, 15.36, 30.72];
//export const vpodSsdCapacities = [1.92];
export const hddCapacities = [16, 18, 24, 30, 32];
export const jbodSizes = [78, 108];
export const compressionRatio = [1, 1.25, 1.5, 1.75, 2, 3, 4, 5, 7, 10];